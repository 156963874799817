import { Tooltip } from "@chakra-ui/react";
import {
  Stop,
  useTrainRoute,
} from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  ClosableAlert,
  HStack,
  Radio,
  RadioGroup,
  Skeleton,
  VStack,
} from "@vygruppen/spor-react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { Select } from "shared/components/forms/Select";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import {
  getToStopOptions,
  splitPassedStopsAndBuildOptGroups,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/affectedStopsUtils";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";

const showWarningWhenPassedStationIsChosen = (
  stops: Stop[],
  fromStopId: string,
) => {
  const selectedStop = stops.find((s) => s.stopId === fromStopId);

  const lastArrivedStopId = stops.findLast((stop) => stop.isArrived)?.stopId;

  if (selectedStop?.isArrived) {
    return lastArrivedStopId !== fromStopId;
  }

  return false;
};

export const TrainStopped: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = ({ mode, trainId, formContext, trainFormPrefix, infrastructureEvent }) => {
  // Cast context fields, the default from react-hook-form isn't good enough
  const { errors } = formContext.formState;
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;
  const control = formContext.control as Control<EitherTrainFormSchema>;
  const resetField =
    formContext.resetField as UseFormResetField<EitherTrainFormSchema>;
  const register =
    formContext.register as UseFormRegister<EitherTrainFormSchema>;
  const unregister =
    formContext.unregister as UseFormUnregister<EitherTrainFormSchema>;

  // Need useState here to be able to unregister when switching tabs
  const [fromStopFieldPath, setFromStopFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.fromStop` as const,
  );
  const [toStopFieldPath, setToStopFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.toStop` as const,
  );
  const [typeFieldPath, setTypeFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.type` as const,
  );

  const { data: trainRoute, status: fetchStatus } = useTrainRoute(trainId);

  // Unregister old fields when field path changes
  useEffect(() => {
    unregister([fromStopFieldPath, toStopFieldPath, typeFieldPath], {
      keepDefaultValue: true,
      keepDirty: true,
      keepDirtyValues: true,
      keepError: true,
      keepIsSubmitSuccessful: true,
      keepIsValid: true,
      keepIsValidating: true,
      keepTouched: true,
      keepValue: true,
    });
    setFromStopFieldPath(`${trainFormPrefix}.trainRouteSection.fromStop`);
    setToStopFieldPath(`${trainFormPrefix}.trainRouteSection.toStop`);
    setTypeFieldPath(`${trainFormPrefix}.trainRouteSection.type`);
  }, [trainFormPrefix]);

  const stops = useMemo(
    () => trainRoute?.train?.stops ?? [],
    [trainRoute?.train?.stops],
  );

  const fromStop = getValues(fromStopFieldPath);
  const toStop = getValues(toStopFieldPath);
  const stopSelectionType = getValues(typeFieldPath);

  const lastStop = stops.at(stops.length - 1)?.stopId;

  const fromStopIsLastStop = stops.length > 0 && fromStop === lastStop;

  // Try to autofill the form based on the trainRoutes currentTrainRouteSection, unless we are in editMode
  useEffect(() => {
    if (infrastructureEvent) {
      // Don't autofill in batch modal
      return;
    }

    if (mode === "create" && fetchStatus === "success") {
      setValue(
        typeFieldPath,
        trainRoute?.train?.currentTrainRouteSection?.toStopId
          ? "BETWEEN_STOPS"
          : "AT_STOP",
        { shouldValidate: true },
      );

      const fromStopId =
        trainRoute?.train?.currentTrainRouteSection?.fromStopId;
      const toStopId = trainRoute?.train?.currentTrainRouteSection?.toStopId;

      if (fromStopId) {
        setValue(fromStopFieldPath, fromStopId, {
          shouldValidate: true,
        });
      }
      if (toStopId) {
        setValue(toStopFieldPath, toStopId, {
          shouldValidate: true,
        });
      }
    }
  }, [fetchStatus, mode]);

  // Should not be able to select toStop if the chosen fromStop is the trains last stop
  useEffect(() => {
    if (fromStopIsLastStop) {
      setValue(typeFieldPath, "AT_STOP");
    }
    // Should not be able to select a toStop that is equal to or before the fromStop
    if (
      stopSelectionType === "BETWEEN_STOPS" &&
      stops.findIndex((s) => s.stopId === toStop) <=
        stops.findIndex((s) => s.stopId === fromStop)
    ) {
      resetField(toStopFieldPath);
    }
  }, [fromStop]);

  const displayStops = useMemo(
    () => splitPassedStopsAndBuildOptGroups(stops),
    [stops],
  );

  const showWarningBox = useMemo(
    () => showWarningWhenPassedStationIsChosen(stops, fromStop),
    [displayStops, fromStop],
  );

  if (fetchStatus === "pending") {
    return <Skeleton height={6} />;
  }

  if (
    fetchStatus === "error" ||
    !trainRoute?.train ||
    trainRoute.train.stops.length <= 0
  ) {
    return (
      <FailureMessage customMessage="Klarte ikke hente ut stoppene til toget. Prøv igjen, eller ta kontakt med IT dersom feilen vedvarer." />
    );
  }

  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <Controller
        control={control}
        name={typeFieldPath}
        render={({ field }) => (
          <RadioGroup
            name="Stasjonsvalg"
            value={field.value ?? ""}
            onChange={field.onChange}
          >
            <Radio value="AT_STOP" name="AT_STOP">
              På stasjon
            </Radio>
            <Radio
              value="BETWEEN_STOPS"
              name="Mellom stasjoner"
              isDisabled={fromStopIsLastStop}
            >
              <Tooltip
                label={
                  fromStopIsLastStop
                    ? `Velg annen "på stasjon"-stopp enn siste stopp`
                    : undefined
                }
              >
                Mellom stasjoner
              </Tooltip>
            </Radio>
          </RadioGroup>
        )}
      />
      <HStack>
        <VStack gap={2} width="100%" alignItems="flex-start">
          <Select
            {...register(fromStopFieldPath)}
            value={fromStop}
            width="200px"
            label={
              stopSelectionType === "AT_STOP" ? "På stasjon" : "Fra stasjon"
            }
            placeholder="Velg stasjon"
          >
            {displayStops}
          </Select>
          <RenderErrorInPath errors={errors} errorPath={fromStopFieldPath} />
        </VStack>

        {stopSelectionType !== "AT_STOP" && (
          <VStack gap={2} width="100%" alignItems="flex-start">
            <Select
              {...register(toStopFieldPath)}
              value={toStop}
              width="200px"
              label="Til stasjon"
              placeholder="Velg stasjon"
            >
              {getToStopOptions(stops, fromStop)}
            </Select>
            <RenderErrorInPath errors={errors} errorPath={toStopFieldPath} />
          </VStack>
        )}
      </HStack>
      {showWarningBox && (
        <Box w="73%">
          <ClosableAlert flexBasis="100%" variant="info">
            Toget har allerede passert stasjonen.
          </ClosableAlert>
        </Box>
      )}
    </VStack>
  );
};
