import { DropsLogAge } from "features/TodoOplogg/Oplogg/OploggElement/utils";
import styled, { css, keyframes } from "styled-components";

interface LogItemProps {
  age: DropsLogAge;
  bookmarked: boolean;
}

const oploggPulse = (fromAlarmColor: string, toAlarmColor: string) => keyframes`
    0% {
        background-color: ${fromAlarmColor};
    }
    50% {
        background-color: ${toAlarmColor};    
    }
    
    100% {
        background-color: ${fromAlarmColor};
    }
`;

export const IndicatorWrapper = styled.div`
  grid-row: 1 / 3;
`;

export const LogItem = styled.li<LogItemProps>`
  display: grid;
  cursor: pointer;
  padding: 12px;
  gap: 6px;
  border-radius: 6px;
  background-color: ${({ age, bookmarked, theme }) => {
    if (age === "NEW" && bookmarked) {
      return theme.colorAlarmFillPulse;
    }
    if (age === "NEW") {
      return theme.colorAlarmFillPulse;
    }
    return "transparent";
  }};

  animation: ${({ age, theme }) =>
    age === "NEW"
      ? css`
          ${oploggPulse(
            theme.colorAlarmFill,
            theme.colorAlarmFillPulse,
          )} 2s ease-in-out 60 forwards
        `
      : "none"};

  outline: ${({ bookmarked, theme }) =>
    bookmarked ? `2px solid ${theme.colorSeparationLine}` : "none"};

  color: ${({ bookmarked, theme }) =>
    bookmarked ? theme.colorTextMain : "transparent"};

  grid-template-columns: 15px max-content 1fr;
  box-sizing: border-box;
`;

export const VerticalLineForHighlightedBox = styled.div`
  width: 1px;
  height: calc(100% + 30px);
  background-color: ${({ color }) => color};
  margin-top: -2px;
  margin-left: 4.8%;
`;

export const VerticalLine = styled.div<{
  color: string;
}>`
  width: 1px;
  height: calc(100% + 20px);
  background-color: ${({ color }) => color};
  margin: auto;
`;
