import { log, LogLevel } from "api/cloudWatch";
import { refreshCognitoTokens } from "api/cognito/cognito";
import { getRefreshToken, setDropsToken } from "api/dropsLocalStorage";
import { AxiosError } from "axios";
import OploggMessages from "features/TodoOplogg/Oplogg/OploggMessages";
import { OploggProvider } from "features/TodoOplogg/Oplogg/useOploggContext";
import { useEffect } from "react";

export const OploggPage = () => {
  const zoomIn = (amount: number) => ({
    transform: `scale(${amount})`,
    transformOrigin: "0 0",
    maxWidth: `${100 / amount}vw`,
    maxHeight: `${100 / amount}vh`,
    gridColumn: "1 / -1",
  });

  const refreshTokens = () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      log(
        LogLevel.warn,
        "OploggPage",
        "Could not refresh Cognito token: refresh token missing",
      );
      return;
    }
    refreshCognitoTokens(refreshToken)
      .then((response) => setDropsToken(response.data.access_token))
      .catch((e: AxiosError) =>
        log(
          LogLevel.warn,
          "OploggPage",
          `Could not refresh Cognito token: refresh token rejected or fetch otherwise rejected with code ${e.code} (${e.status})`,
        ),
      );
  };

  useEffect(() => {
    // Every 10 minutes
    const interval = setInterval(refreshTokens, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={zoomIn(2)}>
      <OploggProvider>
        <OploggMessages showTimeInHeader />
      </OploggProvider>
    </div>
  );
};
