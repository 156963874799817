import styled from "styled-components";
import { logout } from "app/Auth/auth";
import {
  PersonOutline24Icon,
  SpeechBubbleOutline24Icon,
  NightOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Switch, useColorMode, Text } from "@vygruppen/spor-react";

const AdminLinkList = styled.ul`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0rem 0 0;
  margin-bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.colorOutline};
  padding-left: 5%;
  & * ~ * {
    margin-top: 4px;
  }
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
`;

const AdminLinkItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.6rem;
`;

const StyledSwitch = styled(Switch)`
  position: absolute;
  padding-left: 45%;
  margin: 0;
  width: 20px;
`;

const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AdminLinks = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <AdminLinkList>
      <AdminLinkItem>
        <NightOutline24Icon />
        <Text variant="sm" style={{ paddingLeft: "4px" }}>
          Mørk modus
        </Text>
        <StyledSwitch
          size="sm"
          isChecked={colorMode === "dark"}
          onChange={toggleColorMode}
          title="Mørk modus"
        />
      </AdminLinkItem>
      <AdminLinkItem>
        <a
          href="mailto:team-brukerflater-drops@vy.no?cc=itservicesenter@service-now.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "flex", alignItems: "center" }}
        >
          <SpeechBubbleOutline24Icon />
          <Text variant="sm" style={{ paddingLeft: "4px" }}>
            Feedback
          </Text>
        </a>
      </AdminLinkItem>
      <AdminLinkItem>
        <Button onClick={() => logout()} aria-label="Log out">
          <PersonOutline24Icon />
          <Text variant="sm" style={{ paddingLeft: "4px" }}>
            Logg ut av Dashbord
          </Text>
        </Button>
      </AdminLinkItem>
      <div style={{ height: "10px" }} />
    </AdminLinkList>
  );
};

export default AdminLinks;
