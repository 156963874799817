import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  Flex,
  HStack,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { DayPlan } from "features/CenterContent/VehicleDetails/TrainDetails/TrainStaff/DayPlan/DayPlan";
import { trainStaffSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainStaff/types";
import { getPhoneNumberWithoutNOPrefix } from "features/CenterContent/shared/utils";
import React, { FC } from "react";
import { CallButton } from "shared/components/buttons/CallButton";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { Text } from "shared/components/typography/TitleStyles";
import styled, { useTheme } from "styled-components";
import * as z from "zod";

const AccordionGroup = styled(Accordion)`
  ${ObtrusiveScrollCss};
  overflow-y: scroll;
`;

type StaffProps = {
  nominalDate: string | undefined;
  trainId: string | undefined;
};

const TrainStaff: FC<StaffProps> = ({ nominalDate, trainId }) => {
  const theme = useTheme();

  const { status, data } = useQuery({
    queryKey: ["staffOnBoard", nominalDate, trainId],
    queryFn: async ({ signal }) =>
      axiosClient
        .get<z.infer<typeof trainStaffSchema>>(
          `${getBackendUrl()}/crew/${trainId}/${nominalDate}/NO`,
          {
            signal,
          },
        )
        .then((res) => trainStaffSchema.parse(res.data)),
  });

  const getContent = () => {
    switch (status) {
      case "pending":
        return <SkeletonLoader skeletonType="list" />;
      case "success": {
        const { staff } = data;

        if (staff.length > 0) {
          return (
            <AccordionGroup allowToggle overflowY="scroll">
              {staff.map((employee) => (
                <React.Fragment key={employee.employeeNumber + employee.role}>
                  <AccordionItem my={1} w="100%">
                    <AccordionButton
                      as="div"
                      cursor="pointer"
                      backgroundColor={theme.colorBackgroundSecondary}
                      gap={1}
                      color={theme.colorTextMain}
                      position="sticky"
                      top={0}
                      _hover={{
                        backgroundColor: theme.colorBackgroundTetriary,
                      }}
                    >
                      <HStack
                        flexWrap="wrap"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Text bold>
                          {employee.name ?? "Ukjent navn"} (
                          {employee.employeeNumber})
                        </Text>
                        {employee.gsmrPhoneNumber && (
                          <CallButton
                            phoneNumber={getPhoneNumberWithoutNOPrefix(
                              employee.gsmrPhoneNumber,
                            )}
                            size="xs"
                            label={employee.role}
                          />
                        )}
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel
                      color={theme.colorTextMain}
                      display="flex"
                      flexDirection="column"
                    >
                      <DayPlan staff={employee} trainId={trainId} />
                    </AccordionPanel>
                  </AccordionItem>
                  <Divider mb={1} />
                </React.Fragment>
              ))}
            </AccordionGroup>
          );
        }
        return (
          <NoDataMessage reason="Fant ingen informasjon om bemanning på dette toget." />
        );
      }
      case "error":
      default:
        return <FailureMessage />;
    }
  };

  return (
    <Flex direction="column" overflowY="auto" height="100%">
      <Text bold style={{ margin: "12px 0" }}>
        Bemanning
      </Text>
      {getContent()}
    </Flex>
  );
};

export default withErrorBoundary(TrainStaff);
