import { useQuery } from "@tanstack/react-query";
import {
  NotificationFill30Icon,
  NotificationOutline30Icon,
} from "@vygruppen/spor-icon-react";
import { Box, IconButton, Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { AlertsModal } from "./AlertsModal";
import {
  handleTrainResumedResponse,
  TrainResumed,
  TrainResumedResponse,
} from "./useTrainResumed";

const AlertsCounter = styled(Box)`
  position: absolute;
  left: 40px;
  top: -5px;
  background-color: ${({ theme }) => theme.colorBackgroundTetriary};
  border-radius: 5px;
  height: 20px;
  width: 20px;
  z-index: 1000;
`;

const StyledIconButton = styled(IconButton)`
  box-shadow: 0 0 0px 1.5px rgba(100, 100, 100, 0.4);

  &:hover {
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on hover */
  }
`;

export const OpenAlerts = () => {
  const { message: dropsWebsocketMessage } = useDropsWebsocket("trainResumed", [
    "TRAIN_RESUMED",
    "TRAIN_INFORMATION",
  ]);

  const [trainsResumed, setTrainsResumed] = useState<TrainResumed[]>([]);

  const TRAIN_RESUMED_QUERY_KEY = "trainResumed";
  const { data, isSuccess } = useQuery({
    queryKey: [TRAIN_RESUMED_QUERY_KEY],
    retry: 1,
    queryFn: ({ signal }) =>
      queryFnGET<TrainResumedResponse>({
        signal,
        url: `${getBackendUrl()}/train-resumed/`,
      }),
  });

  useEffect(() => {
    if (!isSuccess) return;
    setTrainsResumed(data.trainsResumed);
  }, [isSuccess]);

  useEffect(() => {
    if (
      dropsWebsocketMessage.status === "received" &&
      dropsWebsocketMessage.data.topic === "TRAIN_RESUMED"
    ) {
      handleTrainResumedResponse(
        dropsWebsocketMessage.data.message,
        trainsResumed,
        setTrainsResumed,
      );
    }
    if (
      dropsWebsocketMessage.status === "received" &&
      dropsWebsocketMessage.data.topic === "TRAIN_INFORMATION"
    ) {
      const trainInfoMessage = dropsWebsocketMessage.data.message;
      const matchingVersion = trainInfoMessage.versions.find(
        (version) =>
          version.trainIdentifier.nominal_date &&
          trainsResumed.find(
            (it) =>
              it.trainIdentifier.identifier ===
              version.trainIdentifier.operational_identifier,
          ),
      );
      if (
        matchingVersion?.state === "CLOSED" ||
        matchingVersion?.state === "REPLACED"
      ) {
        const matchingTrainId =
          matchingVersion.trainIdentifier.operational_identifier;
        setTrainsResumed(
          trainsResumed.filter(
            (it) => it.trainIdentifier.identifier !== matchingTrainId,
          ),
        );
      }
    }
  }, [dropsWebsocketMessage]);

  const theme = useTheme();

  const [showModal, setShowModal] = useState(false);

  const hasAlerts = trainsResumed.length > 0;
  return (
    <div style={{ position: "relative" }}>
      {showModal && (
        <AlertsModal
          trainsResumed={trainsResumed}
          setTrainsResumed={setTrainsResumed}
          setShowModal={setShowModal}
        />
      )}
      {hasAlerts && (
        <AlertsCounter>
          <Text color={theme.colorTextMain} variant="xs" textAlign="center">
            {Math.min(trainsResumed.length, 99)}
          </Text>
        </AlertsCounter>
      )}
      <StyledIconButton
        mx="12px"
        variant="ghost"
        aria-label="Train alerts"
        icon={
          hasAlerts ? <NotificationFill30Icon /> : <NotificationOutline30Icon />
        }
        onClick={() => {
          setShowModal(!showModal);
        }}
        color={hasAlerts ? theme.colorWarning : undefined}
      />
    </div>
  );
};
