import { Tooltip } from "@chakra-ui/react";
import {
  Box,
  ClosableAlert,
  HStack,
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import {
  AffectedStopOption,
  getToStopOptions,
  splitPassedStopsAndBuildOptGroups,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/affectedStopsUtils";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import {
  Stop,
  useTrainRoute,
} from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { Select } from "shared/components/forms/Select";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { log, LogLevel } from "api/cloudWatch";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";

type AffectedStopsFieldsProps = {
  alreadySelectedStops?: string[];
  infrastructureEvent?: InfrastructureEventType;
};

export const AffectedStopsFields: FC<
  CommonTrainInfoFormProps &
    CommonSubTypeProps &
    AffectedStopsFieldsProps &
    TrainContext
> = ({
  mode,
  trainId,
  alreadySelectedStops,
  infrastructureEvent,
  formContext,
  trainFormPrefix,
}) => {
  // Cast context fields, the default from react-hook-form isn't good enough
  const { errors } = formContext.formState;
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;
  const control = formContext.control as Control<EitherTrainFormSchema>;
  const resetField =
    formContext.resetField as UseFormResetField<EitherTrainFormSchema>;
  const register =
    formContext.register as UseFormRegister<EitherTrainFormSchema>;
  const unregister =
    formContext.unregister as UseFormUnregister<EitherTrainFormSchema>;

  // Need useState here to be able to unregister when switching tabs
  const [fromStopFieldPath, setFromStopFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.fromStop` as const,
  );
  const [toStopFieldPath, setToStopFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.toStop` as const,
  );
  const [typeFieldPath, setTypeFieldPath] = useState(
    `${trainFormPrefix}.trainRouteSection.type` as const,
  );
  const [affectedStopsFieldPath, setAffectedStopsFieldPath] = useState(
    `${trainFormPrefix}.affectedStops` as const,
  );

  const trainEventType = getValues(`${trainFormPrefix}.type` as const); // Used for autofilling only

  const { data: trainRoute, status: fetchStatus } = useTrainRoute(trainId);

  // Unregister old fields when field path changes
  useEffect(() => {
    unregister(
      [
        fromStopFieldPath,
        toStopFieldPath,
        typeFieldPath,
        affectedStopsFieldPath,
      ],
      {
        keepDefaultValue: true,
        keepDirty: true,
        keepDirtyValues: true,
        keepError: true,
        keepIsSubmitSuccessful: true,
        keepIsValid: true,
        keepIsValidating: true,
        keepTouched: true,
        keepValue: true,
      },
    );
    setFromStopFieldPath(`${trainFormPrefix}.trainRouteSection.fromStop`);
    setToStopFieldPath(`${trainFormPrefix}.trainRouteSection.toStop`);
    setTypeFieldPath(`${trainFormPrefix}.trainRouteSection.type`);
    setAffectedStopsFieldPath(`${trainFormPrefix}.affectedStops`);
  }, [trainFormPrefix]);

  const stops = useMemo(() => trainRoute?.train?.stops ?? [], [trainRoute]);

  const fromStop = getValues(fromStopFieldPath);
  const toStop = getValues(toStopFieldPath);
  const stopSelectionType = getValues(typeFieldPath);

  const firstStop = stops.at(0)?.stopId;
  const lastStop = stops.at(stops.length - 1)?.stopId;

  const fromStopIsLastStop = stops.length > 0 && fromStop === lastStop;

  // Attempt to autofill the form
  useEffect(() => {
    // Do nothing if this train series has already been autofilled, or if
    // its route is empty.
    if (stopSelectionType !== undefined || stops.length === 0) {
      return;
    }

    // Do nothing unless the fetch is finished and successful
    if (fetchStatus !== "success") {
      return;
    }

    // Try to autofill from InfrastructureEvent
    if (mode === "create" && infrastructureEvent) {
      const affectedLegs =
        infrastructureEvent.infrastructureInformation.affectedLegs.flatMap(
          (leg) => [leg.fromStop, leg.toStop],
        );

      const firstStopIndex = stops.findIndex(({ stopId }) =>
        affectedLegs.includes(stopId),
      );
      const lastStopIndex = stops.findLastIndex(({ stopId }) =>
        affectedLegs.includes(stopId),
      );

      if (firstStopIndex === -1 || lastStopIndex === -1) {
        // The route is not affected by this event, cannot autofill from event
      } else if (firstStopIndex === 0 && lastStopIndex === stops.length - 1) {
        // Event covers the entire route
        setValue(typeFieldPath, "WHOLE_ROUTE");
        setValue(fromStopFieldPath, stops[firstStopIndex].stopId);
        setValue(toStopFieldPath, stops[lastStopIndex].stopId);
      } else if (firstStopIndex === lastStopIndex) {
        // The event covers a single stop
        setValue(typeFieldPath, "AT_STOP");
        setValue(fromStopFieldPath, stops[firstStopIndex].stopId);
      } else {
        // The event covers a part of the train route
        setValue(typeFieldPath, "BETWEEN_STOPS");
        setValue(fromStopFieldPath, stops[firstStopIndex].stopId);
        setValue(toStopFieldPath, stops[lastStopIndex].stopId);
      }
    }

    // Try to autofill the form based on the trainRoute's currentTrainRouteSection
    if (
      (mode === "create" && !infrastructureEvent) ||
      (mode === "edit" && !stopSelectionType)
    ) {
      // Handle specific train event special cases
      if (
        [
          TrainEventTypeEnum.TRAIN_CAPACITY_REDUCED,
          TrainEventTypeEnum.TRAIN_CAPACITY_INCREASED,
        ].includes(trainEventType)
      ) {
        // Fill the entire route by default for capacity changes
        setValue(typeFieldPath, "WHOLE_ROUTE");
        setValue(fromStopFieldPath, firstStop!);
        setValue(toStopFieldPath, lastStop!, { shouldValidate: true });
        return;
      }

      if (
        [
          TrainEventTypeEnum.TRAIN_DELAY_EXPECTED,
          TrainEventTypeEnum.TRAIN_DELAYED,
        ].includes(trainEventType)
      ) {
        // Fill the current stop for delays, or set "AT_STOP" without filling
        // (invalid, actionable state) if the train is not running.
        // The latter case should not happen because it is covered by
        // TRAIN_LATE_TO_TRACK
        const stop =
          trainRoute?.train?.currentTrainRouteSection?.toStopId ??
          trainRoute?.train?.currentTrainRouteSection?.fromStopId;

        setValue(typeFieldPath, "AT_STOP", { shouldValidate: true });
        if (stop) {
          setValue(fromStopFieldPath, stop, { shouldValidate: true });
        }
        return;
      }

      if ([TrainEventTypeEnum.TRAIN_LATE_TO_TRACK].includes(trainEventType)) {
        // Fill the first stop for TRAIN_LATE_TO_TRACK
        setValue(typeFieldPath, "AT_STOP");
        setValue(fromStopFieldPath, firstStop!);
        return;
      }

      // Check if the train is currently somewhere along its route
      const currentStopId =
        trainRoute?.train?.currentTrainRouteSection?.fromStopId;
      const currentStopIndex = stops.findIndex(
        (stop) => stop.stopId === currentStopId,
      );

      const nextStopId =
        trainRoute?.train?.currentTrainRouteSection?.toStopId ??
        (currentStopIndex !== -1
          ? stops.at(currentStopIndex + 1)?.stopId
          : undefined);

      // Autofill with next stop if present, otherwise current
      const atStop = nextStopId ?? currentStopId;
      const atStopIsLastStop = atStop && atStop === lastStop;

      // Set to AT_STOP if train is at or approaching last station, BETWEEN_STOPS otherwise
      setValue(typeFieldPath, atStopIsLastStop ? "AT_STOP" : "BETWEEN_STOPS", {
        shouldValidate: true,
      });

      if (atStop) {
        // If a next stop exists, use that as the fromStop
        setValue(fromStopFieldPath, atStop, {
          shouldValidate: true,
        });

        if (!atStopIsLastStop) {
          // Set lastStop as toStop if train has not already reached it
          setValue(toStopFieldPath, lastStop!, { shouldValidate: true });
        }
      } else {
        // Train is not currently running, fill the entire route
        setValue(fromStopFieldPath, firstStop!);
        setValue(toStopFieldPath, lastStop!, { shouldValidate: true });
      }
    }
  }, [
    fetchStatus,
    infrastructureEvent,
    stopSelectionType,
    stops,
    trainFormPrefix,
    trainEventType,
  ]);

  // Hold stopSelectionType in sync with fromStop and toStop
  useEffect(() => {
    if (
      fetchStatus !== "success" ||
      stops.length === 0 ||
      stopSelectionType === "AT_STOP"
    ) {
      return;
    }

    if (
      fromStop === firstStop &&
      toStop === lastStop &&
      stopSelectionType !== "WHOLE_ROUTE"
    ) {
      // The whole route is selected, set stopSelectionType to WHOLE_ROUTE
      setValue(typeFieldPath, "WHOLE_ROUTE");
    } else if (
      (fromStop !== firstStop || toStop !== lastStop) &&
      stopSelectionType === "WHOLE_ROUTE"
    ) {
      // The whole route is not selected, set stopSelectionType to BETWEEN_STOPS
      setValue(typeFieldPath, "BETWEEN_STOPS");
    }
  }, [fromStop, toStop, stopSelectionType, fetchStatus]);

  // Validate consistency of fromStop and toStop along route
  useEffect(() => {
    if (!stopSelectionType || stopSelectionType === "AT_STOP") {
      // Do nothing if state is invalid or if AT_STOP (which is always internally consistent)
      return;
    }

    if (fromStopIsLastStop) {
      // If the last stop is selected as fromStop, and the stopSelectionType is not AT_STOP,
      // toStop would be an empty list and the form would not be submittable.
      // Set the stopSelectionType to AT_STOP to ensure valid state.
      setValue(typeFieldPath, "AT_STOP", {
        shouldValidate: true,
      });
    }

    // Should not be able to select a toStop that is equal to or before the fromStop
    const fromStopIndex = stops.findIndex((s) => s.stopId === fromStop);
    const toStopIndex = stops.findIndex((s) => s.stopId === toStop);
    if (fromStopIndex === -1 || toStopIndex === -1) {
      // Neither fromStop nor toStop are in the route. This may be due to switching between tabs in
      // the batch modal – do nothing and assume it will pass, but log it just in case
      log(
        LogLevel.warn,
        "AffectedStopsFields",
        "either fromStop, toStop or both are not in the selected train's route.",
      );
    } else if (
      stopSelectionType === "BETWEEN_STOPS" &&
      toStopIndex <= fromStopIndex
    ) {
      // toStop is on or before fromStop in the route, empty it
      resetField(toStopFieldPath);
    }
  }, [fromStop, typeFieldPath]);

  // Keep affectedStops in sync with fromStop and toStop
  useEffect(() => {
    // If either fromStop or stopSelectionType are missing, empty affectedStops
    if (!fromStop || !stopSelectionType) {
      setValue(affectedStopsFieldPath, [], {
        shouldValidate: true,
      });
      return;
    }

    // Do nothing if we haven't successfully fetched the route yet
    if (fetchStatus !== "success") {
      return;
    }

    if (!toStop && stopSelectionType !== "AT_STOP") {
      // If fromStop is set but toStop is missing, and selection type is not AT_STOP, empty affectedStops
      setValue(affectedStopsFieldPath, [], {
        shouldValidate: true,
      });
    } else {
      // stopSelectionType is AT_STOP and fromStop is set, or stopSelectionType is otherwise set and both
      // fromStop and toStop are set. Fill affectedStops with the route between them.
      const fromIndex = stops.findIndex((stop) => stop.stopId === fromStop);
      const toIndex =
        toStop && stopSelectionType !== "AT_STOP"
          ? stops.findIndex((stop) => stop.stopId === toStop) + 1 // Plus 1 to include the last stop
          : fromIndex + 1;

      setValue(
        affectedStopsFieldPath,
        stops.slice(fromIndex, toIndex).map((stop) => stop.stopId),
        {
          shouldValidate: true,
        },
      );
    }
  }, [fromStop, toStop, stopSelectionType, stops, fetchStatus]);

  // Group stops by whether the train has passed them or not
  const displayStops = useMemo(
    () => splitPassedStopsAndBuildOptGroups(stops),
    [stops],
  );

  // Compute whether we should show an info box warning that the train has already
  // passed the fromStop.
  const showTrainPassedWarningBox = useMemo(() => {
    if (infrastructureEvent || stopSelectionType === "WHOLE_ROUTE")
      return false;

    const selectedStop = stops.find((s) => s.stopId === fromStop);
    return !!selectedStop?.isArrived;
  }, [stops, fromStop, infrastructureEvent]);

  // Compute whether any of the selected affectedStops are already affected for this train
  const stopsAlreadySelected = useMemo(() => {
    const stopIds = stops.map((s) => s.stopId);

    const selectedStopIds = stopIds.slice(
      fromStop ? stopIds.indexOf(fromStop) : 0,
      toStop ? stopIds.indexOf(toStop) + 1 : -1,
    );

    return (
      (alreadySelectedStops
        ?.filter((stop) => selectedStopIds.includes(stop))
        .map((stopId) => stops.find((s) => s.stopId === stopId))
        .filter((s) => s !== undefined) as Stop[]) ?? []
    );
  }, [fromStop, toStop, alreadySelectedStops, stops]);

  // Compute the options for the toStop Select
  const memoGetToSelect = useMemo(
    () => getToStopOptions(stops, fromStop),
    [stops, fromStop],
  );

  if (fetchStatus === "pending") {
    return <Skeleton height={6} />;
  }

  if (
    fetchStatus === "error" ||
    !trainRoute?.train ||
    trainRoute.train.stops.length <= 0
  ) {
    return (
      <FailureMessage customMessage="Klarte ikke hente ut stoppene til toget. Prøv igjen, eller ta kontakt med IT dersom feilen vedvarer." />
    );
  }

  return (
    <VStack width="100%" alignItems="flex-start" gap={3} my={1}>
      <Controller
        control={control}
        name={typeFieldPath}
        render={({ field }) => (
          <RadioGroup
            name="Stasjonsvalg"
            value={field.value ?? ""}
            onChange={(value) => {
              if (value === "WHOLE_ROUTE" && firstStop && lastStop) {
                setValue(fromStopFieldPath, firstStop);
                setValue(toStopFieldPath, lastStop);
              }
              field.onChange(value);
            }}
          >
            <Radio value="AT_STOP" name="AT_STOP">
              På stasjon
            </Radio>
            <Radio
              value="BETWEEN_STOPS"
              name="Mellom stasjoner"
              isDisabled={fromStopIsLastStop}
            >
              <Tooltip
                label={
                  fromStopIsLastStop
                    ? `Velg annen "på stasjon"-stopp enn siste stopp`
                    : undefined
                }
              >
                Mellom stasjoner
              </Tooltip>
            </Radio>
            <Radio value="WHOLE_ROUTE" name="Hel strekning">
              Hel strekning
            </Radio>
          </RadioGroup>
        )}
      />

      <VStack gap={2} width="100%" alignItems="flex-start">
        <HStack width="100%">
          <VStack gap={0} flexBasis={1} flexGrow={1} alignItems="flex-start">
            <Select
              {...register(fromStopFieldPath)}
              value={fromStop ?? ""}
              minWidth="200px"
              width="100%"
              label={
                stopSelectionType === "AT_STOP"
                  ? "På stasjon"
                  : "Fra og med stasjon"
              }
              placeholder="Velg stasjon"
            >
              {infrastructureEvent
                ? stops.map((stop) => (
                    <AffectedStopOption key={stop.stopId} stop={stop} />
                  ))
                : displayStops}
            </Select>
            <RenderErrorInPath errors={errors} errorPath={fromStopFieldPath} />
          </VStack>

          {stopSelectionType !== "AT_STOP" && (
            <VStack gap={0} flexBasis={1} flexGrow={1} alignItems="flex-start">
              <Select
                {...register(toStopFieldPath)}
                value={toStop ?? ""}
                minWidth="200px"
                width="100%"
                label="Til og med stasjon"
                placeholder="Velg stasjon"
              >
                {memoGetToSelect}
              </Select>
              <RenderErrorInPath errors={errors} errorPath={toStopFieldPath} />
            </VStack>
          )}
        </HStack>
        <RenderErrorInPath errors={errors} errorPath={affectedStopsFieldPath} />
      </VStack>

      {showTrainPassedWarningBox && (
        <Box w="73%">
          <ClosableAlert flexBasis="100%" variant="info">
            Toget har allerede passert stasjonen.
          </ClosableAlert>
        </Box>
      )}

      {stopsAlreadySelected.length > 0 && (
        <ExpandableInfoMessage
          severity="warning"
          title="Stopp på valgt strekning er allerede innstilt"
        >
          <VStack alignItems="flex-start">
            <Text variant="xs" pt={2} fontWeight="bold">
              Følgende stopp er allerede innstilt:
            </Text>
            <Text variant="xs">
              {stopsAlreadySelected.map((s) => s.name.trim()).join(", ")}
            </Text>
          </VStack>
        </ExpandableInfoMessage>
      )}
    </VStack>
  );
};
