import { Box, Flex } from "@vygruppen/spor-react";
import { format, isToday } from "date-fns";
import React, { FC } from "react";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { Text } from "shared/components/typography/TitleStyles";
import colors from "shared/theme/colors";
import { formatDateString } from "shared/utils/datetime";
import styled, { useTheme } from "styled-components";

/* Use as a wrapper for ShiftPlanListItem */
export const DayPlanList = styled.ul`
  ${ObtrusiveScrollCss};
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 6px;
  overflow-y: scroll;
`;

const DateSpan = styled.span`
  margin-left: 9px;
  color: ${({ theme }) => theme.colorTextSecondary};
  text-transform: capitalize;
`;

interface DayPlanListProps extends React.HtmlHTMLAttributes<HTMLLIElement> {
  title: string;
  subTitle: string | null;
  fromLocation: string | null;
  toLocation: string | null;
  startTime: Date | string | null;
  endTime: Date | string | null;
  isSelected: boolean;
  isClickable?: boolean;
}

export const DayPlanListItem: FC<DayPlanListProps> = ({
  title,
  subTitle,
  fromLocation,
  toLocation,
  startTime,
  endTime,
  isSelected = false,
  isClickable = false,
  ...props
}) => {
  const theme = useTheme();
  const isNotToday = startTime && !isToday(new Date(startTime));
  const NO_TIME = "--:--";

  const formatTime = (time: Date | string) =>
    time instanceof Date
      ? format(time, "HH:mm")
      : formatDateString(time, "HH:mm");

  const formatDate = (date: Date | string) =>
    date instanceof Date
      ? format(date, "dd.MM.yy")
      : formatDateString(startTime, "dd.MM.yy");

  const formattedStartTime = startTime ? formatTime(startTime) : NO_TIME;
  const formattedEndTime = endTime ? formatTime(endTime) : NO_TIME;

  return (
    <Box
      display="flex"
      flexDirection="column"
      as="li"
      p={2}
      backgroundColor={theme.colorBackgroundTetriary}
      border={isSelected ? `2px solid ${colors.azure}` : "none"}
      borderRadius="xs"
      _hover={
        isClickable && !isSelected
          ? {
              cursor: "pointer",
              backgroundColor: theme.colorBackgroundTetriary,
            }
          : {}
      }
      {...props}
    >
      <Flex gap={1.5}>
        <Text bold>{title}</Text>
        {subTitle && <Text secondary>{subTitle}</Text>}
      </Flex>
      <Text>
        {fromLocation ?? "ukjent"} - {toLocation ?? "ukjent"}
      </Text>
      <Text>
        {formattedStartTime} - {formattedEndTime}
        {isNotToday && <DateSpan>({formatDate(startTime)})</DateSpan>}
      </Text>
    </Box>
  );
};
