import { generatePath, useNavigate } from "react-router-dom";
import * as ROUTES from "shared/utils/routes";
import { dropsRole } from "stores/dropsRole";
import { isValidUserRole, roleTexts } from "shared/types/roles";
import { TabIds } from "features/CenterContent/RoleContent/getTabsForRole";
import { InfoSelect, Item, ItemLabel } from "@vygruppen/spor-react";

const SelectRole = () => {
  const navigate = useNavigate();

  const { role, setRole } = dropsRole();

  const longestLabel = roleTexts.reduce(
    (prev, curr) => (curr.text.length > prev ? curr.text.length : prev),
    0,
  );

  return (
    <div style={{ minWidth: `${longestLabel + 5}ch` }}>
      <InfoSelect
        name="role"
        label="Rolle"
        value={role}
        items={roleTexts}
        onChange={(value) => {
          if (typeof value !== "string") return;
          if (!isValidUserRole(value)) return;
          setRole(value);
          navigate(
            generatePath(ROUTES.LANDING, {
              roleIdParam: value,
              tabIdParam: TabIds.START,
            }),
          );
        }}
      >
        {(item) => (
          <Item textValue={item.text} key={item.value}>
            <ItemLabel>{item.text}</ItemLabel>
          </Item>
        )}
      </InfoSelect>
    </div>
  );
};

export default SelectRole;
