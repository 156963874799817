import { Button, Td, Text, Tr } from "@vygruppen/spor-react";
import {
  alarmRowOnClick,
  getMappedAlertState,
  getMappedAlertType,
} from "features/CenterContent/shared/TilsynAlerts/utils";
import { formatDateString } from "shared/utils/datetime";
import styled, { useTheme } from "styled-components";
import { VehicleAlert } from "./schema";

const CustomTr = styled(Tr)<{ alert: VehicleAlert }>`
  &&&:hover {
    background-color: ${({ theme, alert }) => {
      if (alert.state === "PENDING") return theme.colorWarningFillPulse;

      if (alert.state === "ACTIVE") return theme.colorAlarmFillPulse;
      return "";
    }};
  }
`;

const CustomTd = styled(Td)<{ alert: VehicleAlert }>`
  color: ${({ theme, alert }) => {
    if (alert.state === "PENDING") return theme.colorWarningTextMain;
    if (alert.state === "ACTIVE") return theme.colorAlarmTextMain;
    return "";
  }};
`;

const CustomText = styled(Text)<{ alert: VehicleAlert }>`
  color: ${({ theme, alert }) => {
    if (alert.state === "PENDING") return theme.colorWarningTextMain;
    if (alert.state === "ACTIVE") return theme.colorAlarmTextMain;
    return "";
  }};
`;

type TilsynAlertRowProps = {
  alert: VehicleAlert;
  fullSize: boolean;
};

const TilsynAlertRow = ({ alert, fullSize = false }: TilsynAlertRowProps) => {
  const theme = useTheme();

  const alertTime = formatDateString(
    alert.createdAt,
    "dd.MM.yyyy HH:mm",
    "Ukjent datoformat",
  );
  const actionTime = formatDateString(
    alert.inProgressAt,
    "dd.MM.yyyy HH:mm",
    "Ikke påbegynt",
  );

  const getStateColor = () => {
    switch (alert.state) {
      case "DELAYED":
      case "PENDING":
        return theme.colorWarningFill;
      case "ACTIVE":
        return theme.colorAlarmFill;
      default:
        return "inherit";
    }
  };

  return (
    <CustomTr alert={alert} backgroundColor={getStateColor()}>
      {fullSize && <CustomTd alert={alert}>{alert.setId}</CustomTd>}
      <CustomTd alert={alert}>{alert.vehicleId}</CustomTd>
      <CustomTd alert={alert}>{alertTime}</CustomTd>
      <CustomTd alert={alert} style={{ wordBreak: "break-word" }}>
        {getMappedAlertType(alert)}
      </CustomTd>
      <CustomTd alert={alert}>{actionTime}</CustomTd>
      {fullSize && (
        <CustomTd alert={alert}>{getMappedAlertState(alert)}</CustomTd>
      )}

      {fullSize && (
        <CustomTd alert={alert}>
          <Button
            title="Åpne i Tilsyn"
            size="xs"
            variant="tertiary"
            onClick={() => alarmRowOnClick(alert)}
          >
            <CustomText alert={alert}>Åpne</CustomText>
          </Button>
        </CustomTd>
      )}
    </CustomTr>
  );
};

export default TilsynAlertRow;
