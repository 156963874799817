import { PersonOutline24Icon } from "@vygruppen/spor-icon-react";
import { Heading, Text } from "@vygruppen/spor-react";
import styled from "styled-components";
import { getUser } from "api/drops/drops";
import { useEffect, useState } from "react";

const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 6px;
  padding: 1rem;

  border-bottom: 1px solid ${({ theme }) => theme.colorOutline};
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
`;

const PersonOutline24IconStyled = styled(PersonOutline24Icon)`
  margin-bottom: 14px;
`;

const fetchUserEmail = async () => {
  try {
    const response = await getUser();
    return response.data.email;
  } catch (error) {
    return null;
  }
};

const ProfileDisplay = () => {
  const [email, setEmail] = useState<string | null>(null);
  useEffect(() => {
    const fetchEmail = async () => {
      const userEmail = await fetchUserEmail();
      setEmail(userEmail);
    };

    fetchEmail();
  }, []);

  return (
    <ProfileContainer>
      <PersonOutline24IconStyled />
      <div>
        <Heading as="h1" variant="sm" fontWeight="Bold">
          {
            (email ?? "")
              .replace(/\./g, " ")
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
              .split("@")[0]
          }
        </Heading>
        <Text variant="ls">{email}</Text>
      </div>
    </ProfileContainer>
  );
};

export default ProfileDisplay;
