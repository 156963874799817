import { useState } from "react";
import { useOnOutsideMouseclick } from "shared/hooks/useOnOutsideMouseclick";
import AdminLinks from "./adminLinks/AdminLinks";
import { ExternalLinks } from "./externalLinks/ExternalLinks";
import { MenuContent, MenuButton, MenuButtonLine, Overlay } from "./MenuStyles";
import ProfileDisplay from "./ProfileDisplay";

export const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { mouseclickRef } = useOnOutsideMouseclick(() => {
    setMenuOpen(false);
  });

  return (
    <div ref={mouseclickRef}>
      <MenuContent $isOpen={menuOpen}>
        <ProfileDisplay />
        <ExternalLinks />
        <AdminLinks />
      </MenuContent>
      <Overlay $isOpen={menuOpen} />
      <div style={{ display: "flex" }}>
        <MenuButton
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          {/* Trasnform into hamburger icon into X for closing */}
          <MenuButtonLine
            $isOpen={menuOpen}
            $isOpenStyling={`
              transform: rotate(45deg) translate(5px, -5px);
          `}
          />
          <MenuButtonLine
            $isOpen={menuOpen}
            $isOpenStyling={`
              opacity: 0;
          `}
          />
          <MenuButtonLine
            $isOpen={menuOpen}
            $isOpenStyling={`
              transform: rotate(-45deg) translate(5px, 5px);
          `}
          />
        </MenuButton>
      </div>
    </div>
  );
};
