import colors from "shared/theme/colors";

export const darkTheme = {
  themeName: "dark",

  /// ///////////////////////////////////////
  // Use SPOR components and color variables if possible
  // Is component is custom or overwritten, use the following color variables
  // Don't create color variables inside your custom components: Create them here
  // Don't even think about using a color without a variable
  /// ///////////////////////////////////////

  // BACKGROUND
  colorBackgroundMain: colors.darkerGrey, // #1C1C1D
  colorBackgroundSecondary: colors.darkGrey, // #2B2B2C
  colorBackgroundTetriary: colors.dimmedDarkGrey, // #414245
  colorHoverItem: colors.whiteAlpha["200"], // #00000 20%

  // TEXT
  colorTextMain: colors.lightGrey, // #F5F5F5
  colorTextSecondary: colors.steel, // #AFB2B3
  colorTextDisabled: colors.dimGrey, // #606568
  colorSelectedText: colors.darkTeal, // #00453E

  // OUTLINES
  colorSeparationLine: colors.dimGrey, // #606568
  colorOutline: colors.whiteAlpha["400"], // rgba(1,1,1,0.4)
  colorOutlineGreen: colors.azure, // #38B49E

  // SEMANTICS
  colorAlarmTextMain: colors.wine, // #99002E
  colorAlarmTextSecondary: colors.raspberry, // #CC003D
  colorAlarm: colors.rose, // #FF3370
  colorAlarmPulse: colors.raspberry, // #CC003D
  colorAlarmFill: colors.blossom, // #FFCCDB
  colorAlarmSecondary: colors.saffron, // #FF9B33
  colorAlarmFillPulse: colors.watermelon, // #FFCCDB
  colorSecondaryAlarmFill: colors.champagne, // #FFCD99
  colorSecondaryAlarmTextMain: colors.wood, // #572F0F
  colorSecondaryAlarmTextSecondary: colors.orange700, // #84441F
  colorWarning: colors.banana, // #FAE053
  colorWarningFill: colors.blonde, // #FDF3BA
  colorWarningTextMain: colors.yellow800, // #84620A
  colorWarningTextSecondary: colors.yellow700, // #84620A
  colorSuccess: colors.azure, // #38B49E
  colorSuccessFill: colors.seaMist, // #CCEAE4
  colorSuccessTextMain: colors.darkTeal, // #00453E
  colorSuccessTextSecondary: colors.pine, // #00685E
  colorInfo: colors.glacier, // #688CBA
  colorInfoFill: colors.cloudy, // #B9C9DE
  colorInfoTextMain: colors.blue900, // #0C2033
  colorInfoTextSecondary: colors.darkBlue, // #003A70
  colorSecondaryAlarmFillPulse: colors.rajah, // #FCEC98
  colorInfoFillPulse: colors.sky, // #A7BCD6
  colorWarningFillPulse: colors.primrose, // #FCEC98
  colorSuccessFillPulse: colors.coralGreen, // #B2DFD7

  // SPECIALS
  primaryBtnColor: colors.darkTeal, // #00453E
  colorSelected: colors.seaMist, // #CCEAE4
  skeletonElementColor: colors.dimGrey, // #606568
};

export type DarkTheme = typeof darkTheme;
