import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { dropsRole } from "stores/dropsRole";
import styled from "styled-components";
import { Button, Heading } from "@vygruppen/spor-react";
import { LinkOutFill18Icon } from "@vygruppen/spor-icon-react";
import chainLink from "shared/icons/chain-link.svg?react";
import { AdminLink, adminLinks } from "./links";

const ExternalLinkList = styled.ul`
  ${ObtrusiveScrollCss};
  list-style: none;
  padding-right: 12px;
  overflow: auto;
  margin: 0;
`;

const TitleContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin-left: 0;
  margin-top: 10px;
`;

const RowContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin-left: 20px;
  transition: color 0.2s linear;

  &:hover {
    color: ${({ theme }) => theme.colorHoverItem};

    & svg path {
      fill: ${({ theme }) => theme.colorHoverItem};
    }
  }
`;

const ChainLinkStyled = styled(chainLink)`
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
  & > * {
    fill: ${({ theme }) => theme.colorTextMain};
  }
`;

export const ExternalLinks = () => {
  const { role: roleState } = dropsRole();

  const linksForUserRole = adminLinks
    .filter((link: AdminLink) => link.roles.includes(roleState))
    .sort((a: AdminLink, b: AdminLink) => a.label.localeCompare(b.label));

  if (linksForUserRole.length > 0) {
    return (
      <ExternalLinkList>
        <TitleContainer as="div">
          <ChainLinkStyled />
          <Heading as="h1" variant="sm" fontWeight="600">
            Lenker
          </Heading>
        </TitleContainer>
        {linksForUserRole.map((link) => (
          <RowContainer
            key={`link-${link.label}`}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              rightIcon={<LinkOutFill18Icon />}
              variant="ghost"
              size="sm"
              width="100%"
            >
              {link.label}
            </Button>
          </RowContainer>
        ))}
      </ExternalLinkList>
    );
  }

  return null;
};
