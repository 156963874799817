import { FC } from "react";
import { HStack, SkeletonText, Stack, Text } from "@vygruppen/spor-react";
import { TrainOutline24Icon } from "@vygruppen/spor-icon-react";
import { useTrainRoute } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { DirectionEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { BatchSubtypeInputs } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { BatchSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { TrainIdentifier } from "@vygruppen/vy-train-map";

const DirectionDescriptor: FC<{
  direction: DirectionEnum;
  representativeTrainId: TrainIdentifier;
}> = ({ direction, representativeTrainId }) => {
  const { data, status } = useTrainRoute(representativeTrainId);

  const skeleton = <SkeletonText height={1} width="10" noOfLines={1} />;

  const origin = data?.train?.stops[0].name ?? data?.train?.origin;
  const destination =
    data?.train?.stops.at(-1)?.name ?? data?.train?.destination;

  return (
    <HStack>
      <TrainOutline24Icon
        style={{ transform: direction === "EVEN" ? "scaleX(-1)" : "scaleX(1)" }}
      />
      {status === "pending" ? (
        <HStack alignItems="center">
          {skeleton}
          <Text fontWeight="bold"> - </Text>
          {skeleton}
        </HStack>
      ) : (
        <Text fontWeight="bold">
          {origin && destination && `${origin} \u2013 ${destination}`} (f.eks{" "}
          {representativeTrainId.identifier})
        </Text>
      )}
    </HStack>
  );
};

type BatchFormProps = {
  eventType: TrainEventTypeEnum;
  direction: DirectionEnum;
  representativeTrainId: TrainIdentifier;
  infrastructureEvent: InfrastructureEventType;
};

export const BatchSubTypeInput: FC<BatchFormProps & BatchSubTypeProps> = ({
  eventType,
  direction,
  representativeTrainId,
  infrastructureEvent,
  activeSeriesKey,
  groupIndex,
}) => (
  <Stack my={3}>
    <DirectionDescriptor
      direction={direction}
      representativeTrainId={representativeTrainId}
    />
    <BatchSubtypeInputs
      activeSeriesKey={activeSeriesKey}
      groupIndex={groupIndex}
      infrastructureEvent={infrastructureEvent}
      type={eventType}
      trainId={representativeTrainId}
      mode="create"
    />
  </Stack>
);
