import { differenceInMinutes } from "date-fns";
import { DefaultTheme } from "styled-components";

const OPLOGG_NEW_TRESHOLD = 6;
const OPLOGG_OLD_TRESHOLD = 8;

export type DropsLogAge = "NEW" | "ALMOST_NEW" | "OLD";
const getAgeInMinutes = (reportedTime: Date): number => {
  const now = new Date();
  return differenceInMinutes(now, reportedTime);
};

export const getAge = (reportedTime: Date): DropsLogAge => {
  const ageInMinutes = getAgeInMinutes(reportedTime);

  if (ageInMinutes <= OPLOGG_NEW_TRESHOLD) {
    return "NEW";
  }

  if (ageInMinutes <= OPLOGG_OLD_TRESHOLD) {
    return "ALMOST_NEW";
  }

  return "OLD";
};
export const getAgeColor = (age: DropsLogAge, theme: DefaultTheme): string => {
  switch (age) {
    case "ALMOST_NEW":
      return theme.colorAlarmSecondary;
    case "NEW":
      return theme.colorAlarm;
    case "OLD":
    default:
      return theme.colorSeparationLine;
  }
};
