import { useQuery } from "@tanstack/react-query";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import {
  OperationalIdentifier_JSON,
  PreviewResponseBatch,
  trainIdentifierToOperationalIdentifierJson,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { getBackendUrl } from "api/common";
import {
  trainFormGroup,
  TrainFormGroup,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { singleTrainFormToRequestBody } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";

type PreviewProps = {
  formData: TrainFormGroup[];
};

export type PreviewTrainRequest = {
  info: TrainInfoRequest | null;
  train: OperationalIdentifier_JSON;
};

type Body = PreviewTrainRequest[];

const QUERY_KEY = "OPERATIONAL_INFO_BATCH_PREVIEW";

export const useTrainInfoBatchPreviews = ({ formData }: PreviewProps) => {
  const parsedForms = formData.map((trainGroup) =>
    trainFormGroup.safeParse(trainGroup),
  );

  const requestBody = parsedForms
    .filter((parsedForm) => parsedForm.success)
    .flatMap<Body>((parsedForm) =>
      parsedForm.data.trainIdsInGroup.map<PreviewTrainRequest>((trainId) => {
        const info = singleTrainFormToRequestBody(
          parsedForm.data.trainForm,
          trainId,
        );

        return {
          train: trainIdentifierToOperationalIdentifierJson(trainId),
          info,
        };
      }),
    );

  const { status: previewStatus, data: previewData } = useQuery({
    queryKey: [
      QUERY_KEY,
      ...parsedForms.flatMap((t) => t.data?.trainIdsInGroup),
      ...parsedForms.map((t) => t.data?.trainForm),
    ],
    refetchOnWindowFocus: false,
    queryFn: ({ signal }) =>
      queryFnPOST<PreviewResponseBatch>({
        url: `${getBackendUrl()}/trainInformation/preview`,
        body: requestBody,
        signal,
      }),
  });

  return { previewData, previewStatus };
};
