import { Td, Tr } from "@vygruppen/spor-react";
import {
  SortDirection,
  tableColumns,
} from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/ReasonCodes";
import CodeAndComment from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/TableContent/CodeAndComment";
import {
  filterRCList,
  sortRCList,
} from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/TableContent/utils";
import {
  ReasonCodeItem,
  ReasonCodes,
} from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/schemas";
import { FC, RefObject, useMemo } from "react";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { useInfiniteScroll } from "shared/hooks/useInfiniteScroll";
import { formatDayDateString, formatTrainDate } from "shared/utils/datetime";
import styled, { useTheme } from "styled-components";

const DelaySpan = styled.span`
  margin-left: 3px;
  color: ${({ theme }) => theme.colorTextSecondary};
`;

export type SortOptions = {
  sortColumn: keyof ReasonCodeItem;
  sortDirection: SortDirection;
};

type TableContentProps = {
  data: ReasonCodes;
  onRowClick: (reasonCode: ReasonCodeItem) => void;
  trainFilter: string;
  codeFilter: string;
  sortOptions: SortOptions;
  tableContainerRef: RefObject<HTMLDivElement>;
};

const TableContent: FC<TableContentProps> = ({
  data,
  onRowClick,
  trainFilter,
  codeFilter,
  sortOptions,
  tableContainerRef,
}) => {
  const theme = useTheme();
  const filteredAndSortedRC = useMemo(
    () =>
      data
        .filter((rc) => filterRCList(rc, trainFilter, codeFilter))
        .sort(sortRCList(sortOptions)),
    [
      data,
      trainFilter,
      codeFilter,
      sortOptions.sortColumn,
      sortOptions.sortDirection,
    ],
  );

  const { numberOfItemsToRender } = useInfiniteScroll({
    initialItemsToRender: 25,
    containerRef: tableContainerRef,
    maxItemsToRender: filteredAndSortedRC.length,
  });

  if (filteredAndSortedRC.length <= 0) {
    return (
      <Tr>
        <Td colSpan={tableColumns.length} p={5}>
          <NoDataMessage reason="Fant ingen årsakskoder som matcher aktivt filter" />
        </Td>
      </Tr>
    );
  }

  return (
    <>
      {filteredAndSortedRC.slice(0, numberOfItemsToRender).map((rc) => {
        const isConductor = rc.employeeRole === "CONDUCTOR";
        const isDriver = rc.employeeRole === "DRIVER";
        return (
          <Tr
            key={rc.id}
            onClick={() => onRowClick(rc)}
            cursor="pointer"
            _hover={{ backgroundColor: theme.colorBackgroundTetriary }}
          >
            <Td p={1}>{formatTrainDate(rc.trainDate)}</Td>
            <Td p={1}>{rc.trainNumber}</Td>
            <Td p={1}>{formatDayDateString(rc.registeredAt)}</Td>
            <Td p={1}>
              {rc.stationName}
              {rc.delay && rc.delay > 0 && (
                <DelaySpan>{`(+${rc.delay})`}</DelaySpan>
              )}
            </Td>
            <Td p={1}>
              {isDriver && (
                <CodeAndComment
                  code={rc.employeeReasonCode}
                  comment={rc.employeeComment}
                />
              )}
            </Td>
            <Td p={1}>
              {isConductor && (
                <CodeAndComment
                  code={rc.employeeReasonCode}
                  comment={rc.employeeComment}
                />
              )}
            </Td>
            <Td p={1}>
              <CodeAndComment
                code={rc.dropsReasonCode}
                comment={rc.dropsComment}
              />
            </Td>
            <Td p={1}>
              <CodeAndComment
                code={rc.tiosReasonCode}
                comment={rc.tiosComment}
              />
            </Td>
          </Tr>
        );
      })}
    </>
  );
};

export default TableContent;
