import { TrainRouteResponse } from "features/CenterContent/VehicleDetails/TrainDetails/useRouteChangedEvents";
import { TrainRoute } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";

export interface Stretch {
  name: string;
  from: string;
  to: string;
  stops: string[];
}

export const ALTERNATIVE_STRETCHES: Stretch[] = [
  {
    name: "Romeriksporten",
    from: "Oslo S",
    to: "Lillestrøm",
    stops: ["OSL", "HLR", "LLS"],
  },
  {
    name: "Hovedbanen",
    from: "Oslo S",
    to: "Lillestrøm",
    stops: [
      "OSL",
      "BR",
      "BRB",
      "ALA",
      "AKE",
      "NYL",
      "GRO",
      "HGA",
      "HØB",
      "LØR",
      "HAB",
      "FJE",
      "STN",
      "SDA",
      "LLS",
    ],
  },

  {
    name: "Østfoldbanen",
    from: "Oslo S",
    to: "Ski",
    stops: [
      "OSL",
      "BEK",
      "NST",
      "LJA",
      "HTO",
      "HMA",
      "RSH",
      "KOL",
      "SOL",
      "MYV",
      "GUD",
      "OPG",
      "VEV",
      "LAN",
      "SKI",
    ],
  },
  {
    name: "Blixtunnelen",
    from: "Oslo S",
    to: "Ski",
    stops: ["OSL", "SKI"],
  },

  {
    name: "Drammenbanen",
    from: "Sandvika",
    to: "Lysaker",
    stops: ["SV", "BLO", "HVK", "STB", "LYS"],
  },
  {
    name: "Askerbanen",
    from: "Sandvika",
    to: "Lysaker",
    stops: ["SV", "LYS"],
  },

  {
    name: "Drammenbanen",
    from: "Sandvika",
    to: "Asker",
    stops: ["SV", "SLE", "BST", "HVA", "VAK", "HØN", "ASR"],
  },
  {
    name: "Askerbanen",
    from: "Sandvika",
    to: "Asker",
    stops: ["SV", "ASR"],
  },

  {
    name: "Drammenbanen",
    from: "Lysaker",
    to: "Asker",
    stops: [
      "LYS",
      "STB",
      "HVK",
      "BLO",
      "SV",
      "SLE",
      "BST",
      "HVA",
      "VAK",
      "HØN",
      "ASR",
    ],
  },
  {
    name: "Askerbanen",
    from: "Lysaker",
    to: "Asker",
    stops: ["LYS", "SV", "ASR"],
  },

  {
    name: "Hovedbanen",
    from: "Lillestrøm",
    to: "Kløfta",
    stops: ["LLS", "LSD", "FRO", "LBG", "KLØ"],
  },
  {
    name: "Gardermobanen",
    from: "Lillestrøm",
    to: "Kløfta",
    stops: ["LLS", "KLØ"],
  },

  {
    name: "Hovedbanen",
    from: "Langeland",
    to: "Eidsvoll",
    stops: ["LAL", "JEH", "NBY", "HSR", "SAD", "DAL", "BØN", "EVL"],
  },
  {
    name: "Gardermobanen",
    from: "Langeland",
    to: "Eidsvoll",
    stops: ["LAL", "GAR", "BKH", "EVV", "EVL"],
  },

  {
    name: "Via Drammen",
    from: "Hønefoss",
    to: "Oslo S",
    stops: [
      "HFS",
      "TYR",
      "VKS",
      "GHS",
      "ÅMO",
      "SEV",
      "HOK",
      "SBG",
      "MJD",
      "DLR",
      "GUL",
      "DRM",
      "BRA",
      "LIE",
      "ERU",
      "ASR",
      "SV",
      "LYS",
      "SKØ",
      "NTH",
      "OSL",
    ],
  },
  {
    name: "Via Roa",
    from: "Hønefoss",
    to: "Oslo S",
    stops: [
      "HFS",
      "HV",
      "JEV",
      "GVL",
      "ROA",
      "GRU",
      "BSR",
      "HST",
      "MON",
      "SY",
      "JEN",
      "HAK",
      "VAR",
      "ÅBY",
      "NIT",
      "MVT",
      "SNI",
      "SDM",
      "KJE",
      "NYD",
      "GRE",
      "TØY",
      "OSL",
    ],
  },
];

export const findAlternativeStretch = (
  stretch: Stretch,
): Stretch | undefined => {
  if (stretch === undefined || stretch.name === "-") {
    return undefined;
  }
  return (
    ALTERNATIVE_STRETCHES.find(
      (s) =>
        (s.from === stretch.from &&
          s.to === stretch.to &&
          s.name !== stretch.name) ||
        (s.from === stretch.to &&
          s.to === stretch.from &&
          s.name !== stretch.name),
    ) ?? ALTERNATIVE_STRETCHES[0]
  );
};

export const isRelevantStretch = (
  stretch: Stretch,
  route: TrainRoute | undefined,
) => {
  if (route === undefined) return false;
  if (stretch.name === "-") return true;
  const stops = route.stops.map((stop) => stop.stopId);
  return (
    stops.includes(stretch.stops[0]) &&
    stops.includes(stretch.stops[stretch.stops.length - 1])
  );
};

export const eventAlreadyExists = (
  stretch: Stretch,
  existingEvents: TrainRouteResponse[],
  uuid?: string,
) => {
  const firstStop = stretch.stops[0];
  const lastStop = stretch.stops[stretch.stops.length - 1];
  return existingEvents.some((event) => {
    if (event.uuid === uuid) {
      return false;
    }
    if (
      firstStop === event.firstStop.ids[0].id &&
      lastStop === event.lastStop.ids[0].id
    ) {
      return true;
    }
    if (
      lastStop === event.firstStop.ids[0].id &&
      firstStop === event.lastStop.ids[0].id
    ) {
      return true;
    }
    return false;
  });
};

export const shouldFlipStretchDirection = (
  stretch: Stretch,
  route: TrainRoute | undefined,
): boolean => {
  if (route === undefined) return false;
  const fromStop = stretch?.stops[0];
  const toStop = stretch?.stops[stretch.stops.length - 1];
  const stops = route?.stops.map((stop) => stop.stopId) ?? [];
  const fromIndex = stops.findIndex((stop) => stop === fromStop);
  const toIndex = stops.findIndex((stop) => stop === toStop);
  if (fromIndex === -1 && toIndex === -1) return false;
  if (fromIndex === -1 || toIndex === -1) return fromIndex < toIndex;
  return toIndex < fromIndex;
};

export const flipIfShouldFlip = (shouldFlip: boolean, stops: string[]) => {
  if (shouldFlip) {
    return stops.toReversed();
  }
  return stops;
};

export const formatStretchName = (stretch: Stretch, flipDirection: boolean) => {
  if (stretch.name === "-") {
    return "-";
  }
  return `${stretch.name} (${flipDirection ? stretch.to : stretch.from} - ${
    flipDirection ? stretch.from : stretch.to
  })`;
};

export const affectedstopsMatchStopsInStretch = (
  affectedStops: string[],
  stops: string[],
) => {
  if (affectedStops.length !== stops.length) return false;
  const lastIndex = affectedStops.length - 1;
  return (
    (affectedStops[0] === stops[0] &&
      affectedStops[lastIndex] === stops[lastIndex]) ||
    (affectedStops[0] === stops[lastIndex] &&
      affectedStops[lastIndex] === stops[0])
  );
};
