import { Flex } from "@chakra-ui/react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { OpLoggInput } from "./OploggInput";
import OploggMessages from "./OploggMessages";

const Oplogg = () => (
  <Flex flexDir="column" overflowY="auto" w="420px">
    <OpLoggInput />
    <OploggMessages />
  </Flex>
);

export default withErrorBoundary(Oplogg);
